import http from '@/utils/http'

// 登录
export const userLogin = (data) => {
  return http({
    method: 'post',
    url: "/api/h5/user/login",
    data
  })
}

// 注册
export const userRegister = (data) => {
  return http({
    method: 'post',
    url: "/api/h5/user/register",
    data
  })
}

// 登出
export const userLogout = (data) => {
  return http({
    method: 'post',
    url: "/api/h5/user/logout",
    data
  })
}

// 获取用户信息
export const getUserInfo = (data) => {
  return http({
    method: 'post',
    url: '/api/h5/user/userinfo',
    data
  })
}

// 获取修改密码
export const userPassword = (data) => {
  return http({
    method: 'post',
    url: '/api/h5/user/password',
    data
  })
}

// 发送短信
export const sendSMS = (data) => {
  return http({
    method: 'post',
    url: '/api/h5/op/sms',
    data
  })
}

// 修改密码
export const updatePassword = (data) => {
  return http({
    method: 'put',
    url: '/api/h5/user/password',
    data
  })
}

// 忘记密码
export const resetpass = (data) => {
  return http({
    method: 'post',
    url: '/api/h5/op/resetpass',
    data
  })
}

export const goodsappconfig = () => {
  return http({
    url: '/api/h5/goods/appconfig',
    method: 'post'
  })
}

<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
import { goodsappconfig } from './api/login';
import config from './config/app';

export default {
  name: 'App',
  components: {
  },
  async mounted() {
    await goodsappconfig().then(res => {
      config.title = res.data.appname;
      document.title = config.title
    })
  }
}
</script>

<style lang="less">
@import './assets/css/utils.less';
// body {
//   max-width: 500px;
//   margin: 0 auto;
// }
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif, PingFang SC, DIN;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333;
  font-size: 14px;
  // background-color: #F6F4F2;
  }
</style>
